<template>
  <div class="columnList">
    <div class="conent">
      <!-- 栏目页大图 -->
      <div class="lbt">
        <img src="~@/assets/img/banner.jpg" />
      </div>
      <div class="tjlm_bg">
        <div class="tjlm">
          <ul v-for="(item, index) in tablist" :key="index">
            <li :id="item.id" :class="cuIdx === index ? 'tjlm_on' : ''">
<!--              <a v-if="item.url" :href="item.url" target="_blank">{{-->
<!--                  item.id-->
<!--                }}</a>-->
              <span
                  :title="item.fullName"
                  @click="reload(item.uuid, index)"
              >{{ item.fullName }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="w1380 lmy" v-if="tablist && tablist.length && !showDetail && !allDetail">
        <div class="page-herader">
          <span>{{tablist[cuIdx].fullName}}</span>
        </div>
        <div class="every-list">
          <div v-for="(item, index1) in bottomList" :key="item.id">
            <a
                v-if="item.mapProperties?.erOriginLink"
                :href="item.mapProperties?.erOriginLink"
                target="_blank"
                style="color: #000"
            >{{ item.title }}</a
            >
            <a
                style="color: #000"
                v-else
                :href="`${pathname}#/commonList?id=${cuIdx}&columnId=${columnId}&showDetail=1&index=${index1}`"
                target="_blank"
            >{{ item.title }}</a
            >
          </div>
        </div>
      </div>
      <div class="w1380 lmy" v-if="showDetail || allDetail" style="padding: 1.04167vw">
        <div class="page-herader">
          <span>{{tablist[cuIdx] && tablist[cuIdx].fullName}}</span>
        </div>
        <div v-html="bottomList && bottomList[bottomIndex] && bottomList[bottomIndex].content"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      cuIdx: 0,
      tablist: [],
      currentTabComponent: "",
      columnId: '',
      bottomList: [],
      showDetail: false,
      allDetail: false,
      pathname: "",
      bottomIndex: 0,
      firstIn: true,
    };
  },
  created() {
    this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
    this.columnId = this.$route.query.columnId ? this.$route.query.columnId : '';
    this.showDetail = this.$route.query.showDetail ? !!this.$route.query.showDetail : false;
    this.allDetail = this.$route.query.allDetail ? !!this.$route.query.allDetail : false;
    this.bottomIndex = this.$route.query.index ? this.$route.query.index : 0;

    this.pathname = window.location.pathname;
    // this.currentTabComponent = this.tablist[this.cuIdx].components;
    this.getColumnFun();
  },
  methods: {
    async reload(uuid, index) {
      this.cuIdx = index;
      if (!this.firstIn) {
        this.showDetail = false;
      }
      this.firstIn = false;
      // this.currentTabComponent = component;
      console.log('uuid', uuid)
      const params = {
        pageNumber: 0,
        pageSize: 100,
        columnIds: uuid,
        // columnIds: '64647dfce4b0eee6a5246170',
      };
      const res = await this.API.basic.getSignaturesList(params);
      this.bottomList = res.data.content;
      console.log('reload', res)
    },
    async getColumnFun() {
      // const params = {
      //   pageNumber: 0,
      //   pageSize: 1,
      //   columnIds: '652cf4cae4b017e9bcb0b3f0',
      // };
      // const res = await this.API.basic.getHomeRotographList(params);
      // const res = await this.API.basic.getColumn('tree', this.columnId);
      const res = await this.API.basic.getColumn();
      this.tablist = res.data.filter(item => item.uuid === this.columnId)[0].children;
      console.log('res', res,  this.tablist)
      await this.reload(this.tablist[this.cuIdx].uuid, this.cuIdx)
      this.$forceUpdate();
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.showDetail = this.$route.query.showDetail ? !!this.$route.query.showDetail : false;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.columnList {
  .conent .lbt {
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }

  .every-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    &>div {
      width: calc(33% - 20px);
      margin-right: 20px;
      font-size: 20px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #e3e3e3;
    }
  }
}
</style>
